import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useGlobalContext } from "../context/context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const Login = () => {
  const navigate = useNavigate();
  const { baseUrl, getUser } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [passvisible, setPassVisible] = useState(false);
  const toggleVisible = () => setPassVisible(!passvisible);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const storeuserPassword = (userpass, id, token) => {
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { userpass: userpass },
        { headers: { token: token } }
      )
      .then((response) => {})
      .catch((error) => {});
  };

  const getLoginDetails = async (data) => {
    const { password } = data;
    setLoading(true);
    axios
      .post(`${baseUrl}auth/login`, data)
      .then((data) => {
        if (data.status === 200) {
          storeuserPassword(password, data.data._id, data.data.accessToken);
          setLoading(false);
          sessionStorage.setItem("user", JSON.stringify(data.data));
          sessionStorage.setItem(
            "accessToken",
            JSON.stringify(data.data.accessToken)
          );
          getUser(data.data.accessToken, data.data._id);
          navigate("/wallet");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data === "Wrong credentials") {
          toast.error("Email or Password is Incorrect");
        }
      });
  };

  return (
    <>
      <div className="parent-con">
        <div className="login-con">
          <Link className="link" to="/">
            <FaTimes color="#001e88" />
          </Link>
          <h3>Login in</h3>
          <p>
            Don't have an account?
            <Link className="reset_text" to="/auth/register">
              Create your account
            </Link>
            , it takes less than a minute.
          </p>
          {/* Login Form */}
          <form
            className="login-form"
            onSubmit={handleSubmit((data) => getLoginDetails(data))}
          >
            <div>
              <p>Email</p>
              <input
                className="input"
                type="email"
                {...register("email", { required: "Email is required" })}
              />
              <p className="login-error-message">{errors.email?.message}</p>
            </div>
            <div>
              <p>Password</p>
              <div className="input password_input">
                <input
                  type={passvisible ? "text" : "password"}
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                {passvisible ? (
                  <MdVisibility onClick={toggleVisible} color="#001e88" />
                ) : (
                  <MdVisibilityOff onClick={toggleVisible} color="#001e88" />
                )}
              </div>
              <p className="login-error-message">{errors.password?.message}</p>
            </div>
            <button className="btn">
              {loading ? (
                "Please Wait! while we load contents for security reasons"
              ) : (
                <span>Login</span>
              )}
            </button>
          </form>
          <p style={{ marginTop: 20 }}>
            Forget Password? <span> </span>
            <Link to={`/auth/forget`} className="reset_text">
              Reset Password
            </Link>
          </p>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default Login;
